export default {
  reset_pass: "/api/auth/reset-pass",
  forgot_pass: "/api/auth/forgot-pass",
  login: "/api/auth/login",
  delete_account: "/api/auth/user",
  signup: "/api/auth/signup",
  confirm_account: "/api/auth/verify-signup",
  logout: "/api/auth/logout",
  check_login: "/api/auth/login",
  piese: "/api/piese-moto",
  favorite: "/api/piese-moto/favorites",
  unfavorite: "/api/piese-moto/unfavorite",
  piesa: "/api/piesa",
  cerere: "/api/cerere",
  vandut: "/api/piesa-vanduta",
  profile: "/api/profil",
  cereri: "/api/cereri",
  cauta_cerere: "/api/cereri/search",
  cauta_piesa: "/api/piese-moto/search",
  marci: "/api/motociclete",
  adrese: "/api/adrese",
  favorites_login: "/api/piese-moto/favorites-on-login",
  google_auth: "/api/auth/google",
};
