<template>
  <div class="fallback m-t-48 text-center flex columns">
    <template v-if="isSearchResult">
      <p cy="basefallback-sorry">Ne pare rau!</p>
      <p cy="basefallback-no-result">Cautarea nu a produs niciun rezultat.</p>
      <button @click="handleReset">Resetează cautarea</button>
    </template>
    <p v-else-if="isProfile || isFavorite" cy="basefallback-piese">
      Momentan nu ai {{ itemType }} salvate.
    </p>
    <p v-else cy="basefallback-not-existing">
      Momentan nu exista {{ itemType }} in database-ul nostru.
    </p>
    <BaseAdd v-if="!isSearchResult" :action-path="actionPath" />
  </div>
</template>

<script setup>
import BaseAdd from "./BaseAdd.vue";

defineProps({
  itemType: {
    type: String,
    required: true,
  },
  actionPath: {
    type: String,
    required: true,
  },
  isProfile: {
    type: Boolean,
    default: false,
  },
  isSearchResult: {
    type: Boolean,
    default: false,
  },
  isFavorite: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["reset"]);

function handleReset() {
  emit("reset");
}
</script>

<style lang="scss">
.fallback {
  gap: 16px;

  button {
    max-width: fit-content;
    margin: 0 auto;
  }

  p {
    margin: 0;
  }
}
</style>
