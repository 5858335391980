<template>
  <button
    class="favorite flex-center"
    cy="favorite-item"
    @click="handleFavorite"
  >
    <img :src="isAlreadyFavorite ? favoriteFull : favoriteBlack" alt="" />
  </button>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import favoriteBlack from "/public/favorite-icon-black.svg";
import favoriteFull from "/public/favorite-icon-full.svg";

const { state, dispatch, commit } = useStore();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  isAlreadyFavorite: {
    type: Boolean,
    required: true,
  },
});

const isActive = computed(() => state.auth.account.active);

async function handleFavorite() {
  const id = props.id;
  const action = props.isAlreadyFavorite ? "remove_favorite" : "add_favorite";
  const mutation = props.isAlreadyFavorite
    ? "ROOT_REMOVE_FROM_FAVORITE"
    : "ROOT_ADD_TO_FAVORITE";

  if (isActive.value) return dispatch(action, { id });
  if (!isActive.value) return commit(mutation, id, { root: true });
}
</script>

<style lang="scss">
.favorite {
  background: none;
  padding: 0;
  cursor: pointer;
}
</style>
