import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { GlobalComponentsPlugin } from "./plugins";

import "./styles/base.scss";
import "./styles/typography.scss";
import "./styles/mixins.scss";
import "./styles/helper-classes.scss";

const app = createApp(App);

app.use(store).use(router).use(GlobalComponentsPlugin).mount("#app");
