<template>
  <button
    class="menu-button"
    :class="{ active: menuIsOpen }"
    @click="() => emit('click')"
  >
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  isOpen: Boolean,
});

const emit = defineEmits(["click"]);

const menuIsOpen = computed(() => props.isOpen);
</script>

<style lang="scss">
@import "../styles/vars";

.menu-button {
  display: flex;
  flex-direction: column;
  width: 25px;
  padding: 0;
  background: none;
  cursor: pointer;

  span {
    background: #000000;
    border-radius: 10px;
    height: 4px;
    margin: 3px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 50%;
  }

  &.active span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotate(45deg) translate(16px, 2px);
  }

  &.active span:nth-of-type(2) {
    transform-origin: top;
    transform: rotate(-45deg) translate(0, -1px);
  }

  &.active span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(1px, -17px) rotate(45deg);
  }
}
</style>
