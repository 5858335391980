<template>
  <div
    v-if="isModal"
    class="base-modal fixed flex-center left0 top0 z99 wh100"
    cy="base-modal"
  >
    <div class="relative">
      <button class="close-btn absolute" @click="handleModalClose">
        <img src="/reset-icon.svg" />
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const { state, commit } = useStore();

const isModal = computed(() => state.is_modal);

function handleModalClose() {
  commit("SET_ROOT_STATE", { key: "is_modal", value: false });
}
</script>

<style lang="scss">
.base-modal {
  background-color: rgba(0, 0, 0, 0.5);
  > div {
    max-width: 476px;
    padding: 32px;
    border-radius: 4px;
    background-color: white;
  }

  .close-btn {
    right: 11px;
    top: 11px;
    padding: 0;
    background: none;
  }
}
</style>
