import {
  BaseInput,
  BaseSelect,
  BaseRadio,
  BaseFileUpload,
  BaseModal,
  BaseConfirmation,
  BaseLogout,
  BaseFallback,
  BasePagination,
} from "@/components";

const GlobalComponentsPlugin = {
  install(Vue) {
    Vue.component("BaseInput", BaseInput);
    Vue.component("BaseSelect", BaseSelect);
    Vue.component("BaseRadio", BaseRadio);
    Vue.component("BaseFileUpload", BaseFileUpload);
    Vue.component("BaseModal", BaseModal);
    Vue.component("BaseConfirmation", BaseConfirmation);
    Vue.component("BaseLogout", BaseLogout);
    Vue.component("BaseFallback", BaseFallback);
    Vue.component("BasePagination", BasePagination);
  },
};

export default GlobalComponentsPlugin;
