<template>
  <div class="base-input" :class="{ stacked }">
    <label v-if="label" :for="name">{{ label }}</label>
    <textarea
      v-if="type === 'textarea'"
      :id="name"
      :name="name"
      :disabled="disabled"
      :value="value"
      :placeholder="placeholder ?? name"
      :cy="cy"
      autocapitalize="off"
      @input="handleInput"
    >
    </textarea>
    <input
      v-else
      :id="name"
      :name="name"
      :type="type || 'text'"
      :value="value"
      :placeholder="placeholder ?? name"
      :cy="cy"
      :min="type === 'number' ? min : null"
      :disabled="disabled"
      autocapitalize="off"
      @input="handleInput"
    />
    <span
      v-if="validate"
      :class="{ success: rule === 'success' }"
      cy="validation"
      >{{ validationMsgs[rule] }}</span
    >
  </div>
</template>

<script setup>
const props = defineProps({
  validate: Boolean,
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  value: {
    type: String,
    default: "",
  },
  rule: {
    type: String,
    default: "",
  },
  cy: {
    type: String,
    default: null,
  },
  min: {
    type: Number,
    default: 0,
  },
  stacked: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["input"]);

const validationMsgs = {
  required: "Acest camp este necesar.",
  alreadyRegistered: "Exista deja un cont cu acest email",
  wrongCredentials: "User sau parola incorecta.",
  noPassMatch: "Parolele trebuie sa fie la fel",
  invalidEmail: "Acest email nu corespunde unui cont.",
  success: "Actiune completata cu succes!",
  unauthorized: "Trebuie sa fii logat pentu a efectua aceasta actiune",
};

function handleInput(ev) {
  emits("input", { name: props.name, value: ev.target.value });
}
</script>

<style lang="scss">
@import "../styles/vars";

.base-input {
  @media only screen and (min-width: 0) {
    display: flex;
    align-items: center;
    gap: 8px;

    &.stacked {
      flex-direction: column;
      align-items: flex-start;

      input,
      textarea {
        flex: initial;
      }
    }

    input {
      height: 38px;
    }

    label {
      display: block;
      font-size: 1.6rem;
      white-space: nowrap;
    }

    span {
      margin-top: 4px;
      display: inline-block;
      color: $red;
      font-size: 1.4rem;
      height: 17px;

      &.success {
        color: rgb(0, 212, 0);
      }
    }

    input,
    textarea {
      padding: 8px 0 8px 12px;
      font-size: 1.6rem;
      border: 1px solid lightgray;
      width: 100%;
      border-radius: 4px;
    }

    textarea {
      width: 100%;
      min-height: 80px;
    }
  }
}
</style>
