<template>
  <form @submit.prevent="handleSubmit">
    <slot></slot>

    <div class="actions flex flex-between m-t-24">
      <button type="button" cy="cancel-profile-btn" @click="handleAbortChanges">
        Anuleaza
      </button>
      <button
        class="green-btn"
        type="submit"
        :disabled="disableSubmit"
        cy="save-profile-btn"
      >
        Salveaza {{ isFetching ? "loading" : "" }}
      </button>
    </div>
  </form>
</template>

<script setup>
const { disableSubmit } = defineProps({
  disableSubmit: Boolean,
  isFetching: Boolean,
});

const emit = defineEmits(["submit", "abort"]);

function handleSubmit() {
  emit("submit");
}

function handleAbortChanges() {
  emit("abort");
}
</script>

<style lang="scss"></style>
