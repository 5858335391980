<template>
  <div class="avatar-group">
    <BaseAvatar
      :initials="getInitials(profil?.prenume ?? 'username', profil?.nume)"
      :size="size"
    />
    <div class="avatar-details">
      <span
        >{{ capitalize(profil?.prenume ?? "username") }}

        {{ profil?.nume && capitalize(profil?.nume) }}</span
      >
      <span> {{ user.name }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { capitalize, getInitials } from "@/utils/helpers";
import { BaseAvatar } from "@/components";

defineProps({
  size: {
    type: Number,
    default: 40,
  },
});

const { state } = useStore();

const profil = computed(() => state.profile.user_profile);
const user = computed(() => state.auth.user);
</script>

<style lang="scss">
.avatar-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  .avatar-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  span:nth-child(1) {
    font-weight: bold;
  }
}
</style>
