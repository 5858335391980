import axios from "axios";
import api from "../api";

export const state = {
  edit_profile: {},
  change_password: {},
  user_profile: {
    favorites: new Set(),
  },
  is_fetching: false,
  valid_profile: false,
};

export const mutations = {
  SET_PROFILE_STATE(state, { key, value }) {
    state[key] = value;
  },
  SET_PROFILE_VALID(state, { prenume, oras, judet, telefon }) {
    state.valid_profile = prenume && oras && judet && telefon;
  },
  SET_PROFILE_FAVORITE(state, favorites) {
    favorites.forEach((favorite) => {
      state.user_profile.favorites?.add(favorite);
    });
  },
  UPDATE_PROFILE_FIELD(state, { key, value }) {
    state.edit_profile[key] = value;
  },
  UPDATE_PROFILE_PASS_FIELD(state, { key, value }) {
    state.change_password[key] = value;
  },
  RESET_PROFILE_FIELDS(state, data) {
    const { nume, prenume, oras, judet, telefon, id } =
      data ?? state.user_profile;
    state.edit_profile = { nume, prenume, oras, judet, telefon, id };
  },
  RESET_PROFILE_PASS_FIELDS(state) {
    state.change_password = {};
  },
};

export const getters = {
  passwords_match({ change_password }) {
    return change_password.parola_noua === change_password.confirma_parola;
  },
};

export const actions = {
  async get_profil({ commit }) {
    try {
      commit("SET_PROFILE_STATE", { key: "is_fetching", value: true });
      const { data } = await axios.get(api.profile);
      commit("SET_PROFILE_STATE", {
        key: "user_profile",
        value: { ...data, favorites: new Set(data.favorites) },
      });
      commit("RESET_PROFILE_FIELDS", data);
      commit("SET_PROFILE_VALID", data);
      commit("SET_PROFILE_STATE", { key: "is_fetching", value: false });
    } catch (err) {
      commit("SET_PROFILE_STATE", { key: "is_fetching", value: false });
      console.log(err);
    }
  },
  async update_profil({ state, commit }) {
    try {
      commit("SET_PROFILE_STATE", { key: "is_fetching", value: true });
      const { data } = await axios.put(api.profile, state.edit_profile);
      commit("SET_PROFILE_STATE", {
        key: "user_profile",
        value: {
          ...state.user_profile,
          ...data,
          favorites: new Set(data.favorites),
        },
      });
      commit("SET_PROFILE_VALID", data);
      commit("SET_PROFILE_STATE", { key: "is_fetching", value: false });
    } catch (err) {
      console.log(err);
      commit("SET_PROFILE_STATE", { key: "is_fetching", value: false });
    }
  },
};
