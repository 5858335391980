<template>
  <div class="search-bar flex columns" :class="{ show: isVisible }">
    <slot
      name="revealing-ui"
      :show-sort-ui="showSortUi"
      :show-filters-ui="showFiltersUi"
      :hide-sort-ui="switchSortUiState"
      :hide-filters-ui="switchFiltersUiState"
    ></slot>

    <div class="search-field">
      <div class="inner-container flex">
        <SortFilterButton
          v-if="hasFilters"
          :mobile-ui-open="showFiltersUi"
          :has-active-conditions="hasActiveFilters"
          cy="filters-btn"
          @click="switchFiltersUiState"
        >
          <FiltersIcon />
        </SortFilterButton>
        <SortFilterButton
          v-if="hasSort"
          :mobile-ui-open="showSortUi"
          :has-active-conditions="!hasDefaultSorting"
          cy="sorting-btn"
          @click="switchSortUiState"
        >
          <SortIcon />
        </SortFilterButton>
        <SearchInput :variant="variant" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import SearchInput from "./SearchInput.vue";
import SortFilterButton from "./SortFilterButton.vue";
import SortIcon from "./icons/SortIcon.vue";
import FiltersIcon from "./icons/FiltersIcon.vue";

const props = defineProps({
  shouldAppear: {
    type: Boolean,
    default: false,
  },
  hasFilters: {
    type: Boolean,
    default: false,
  },
  hasSort: {
    type: Boolean,
    default: false,
  },
  isSearchResult: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    required: true,
  },
});

const { state, getters } = useStore();

onBeforeMount(() => {
  setTimeout(() => {
    initialShow.value = true;
  }, 0);
});

const initialShow = ref(false);
const showSortUi = ref(false);
const showFiltersUi = ref(false);
const sorting = computed(() => state.piese.sorting);
const isBottomScroll = computed(() => state.is_bottom_scroll);
const hasActiveFilters = computed(
  () => !getters.has_default_filters({ status: "current" }),
);
const hasDefaultSorting = computed(
  () => sorting.value.default.sort === sorting.value.current.sort,
);
const isVisible = computed(
  () =>
    initialShow.value &&
    props.shouldAppear &&
    (!isBottomScroll.value || showFiltersUi.value || showSortUi.value),
);

function switchFiltersUiState() {
  if (showSortUi.value) {
    showSortUi.value = !showSortUi.value;
  }
  showFiltersUi.value = !showFiltersUi.value;
}

function switchSortUiState() {
  if (showFiltersUi.value) {
    showFiltersUi.value = !showFiltersUi.value;
  }
  showSortUi.value = !showSortUi.value;
}
</script>

<style lang="scss">
.search-bar {
  left: 0;
  bottom: 66px;
  position: fixed;
  width: 100%;
  height: 60px;
  transform: translateY(128px);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateY(0) !important;
  }

  & > div {
    padding: 12px;
  }

  .search-field {
    background-color: lightgray;
    height: 100%;
    flex: 1;
    z-index: 10;

    .inner-container {
      height: 100%;
      gap: 8px;
      max-width: 656px;
      margin: 0 auto;
    }
  }
}
</style>
