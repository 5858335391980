<template>
  <div class="search">
    <label for="search" class="search-label relative">
      <input
        id="search"
        :value="searchQuery"
        :disabled="disabled"
        type="text"
        class="h100"
        placeholder="cauta dupa nume, marca sau model, cod OEM"
        cy="search-input"
        @input="setSearchValue"
        @keyup.enter="handleSearch"
      />
      <button
        v-show="searchQuery"
        class="reset-search-btn absolute"
        cy="x-btn"
        @click="handleResetSearch"
      >
        <img src="/reset-icon.svg" />
      </button>
    </label>
    <button
      class="search-btn"
      cy="search-btn"
      :disabled="disabled"
      @click="handleSearch"
      @keyup.enter="handleSearch"
    >
      <img src="/search.svg" alt="" />
    </button>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useUpdateRouteQuery } from "@/utils/composables";

const props = defineProps({
  variant: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { updateQueryParams, resetQueryParams } = useUpdateRouteQuery();
const { state, commit } = useStore();
const route = useRoute();

const isSearchResult = computed(() => state[props.variant].is_search_result);
const currentPage = computed(() => state[props.variant].current_page);
const searchQuery = computed(() => state.search_value);
const setStateCommit = computed(
  () => `SET_${props.variant.toUpperCase()}_STATE`,
);

function setSearchValue(e) {
  commit("ROOT_SET_SEARCH_VALUE", e.target.value);
}

async function handleSearch() {
  if (searchQuery.value) {
    commit(setStateCommit.value, { key: "current_page", value: "1" });
    commit(setStateCommit.value, {
      key: "bookmarks",
      value: [],
    });

    updateQueryParams({
      query: {
        ...route.query,
        search: searchQuery.value,
      },
    });
  }
}

async function handleResetSearch() {
  if (isSearchResult.value && route.query?.search === searchQuery.value) {
    commit(setStateCommit.value, { key: "bookmarks", value: [] });
    commit(setStateCommit.value, { key: "current_page", value: "1" });

    resetQueryParams({
      query: route.query,
      page: currentPage.value,
      removeQueries: ["search"],
    });
  }

  commit("ROOT_SET_SEARCH_VALUE", "");
}
</script>

<style lang="scss">
.search {
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: flex-end;

  .search-label {
    width: 100%;
    display: flex;

    input {
      width: 100%;
      outline: none;
      width: 100%;
      border-radius: 4px;
      border: 1px solid lightgray;
      font-size: 1.6rem;
      padding: 2px 0 2px 8px;
    }
  }

  .reset-search-btn,
  .search-btn {
    background-color: white;
    padding: 0;
  }

  .reset-search-btn {
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .search-btn {
    border-radius: 4px;
    border: solid 1px lightgray;

    img {
      height: 100%;
    }
  }
}
</style>
