<template>
  <label class="base-radio" :for="label.replace(/ /g, '-')">
    <input
      :id="label.replace(/ /g, '-')"
      type="radio"
      :name="name"
      :value="value"
      :checked="selected === value"
      @change="handleChange"
    />
    <span class="pointer">{{ label }}</span>
  </label>
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  selected: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["change"]);

function handleChange() {
  emits("change", { label: props.label });
}
</script>

<style lang="scss">
.base-radio {
  display: block;
  span {
    margin-left: 8px;
    font-size: 2rem;
  }
}
</style>
