<template>
  <div class="base-filters flex flex-center" cy="base-filters">
    <div class="content-wrapper">
      <h3>Filtre</h3>
      <div class="flex flex-between w100 gap-8">
        <BaseSelect
          name="marca"
          placeholder="Marca"
          :options="options.marci"
          :value="
            getSelectValue({
              value: filters.temp.marca,
              options: options.marci,
            })
          "
          cy="filters-marca"
          @select="handleBrandFilter"
        />
        <BaseSelect
          name="model"
          placeholder="Model"
          :options="options.modele"
          :value="
            getSelectValue({
              value: filters.temp.model,
              isFetching: fetchingModels,
              options: options.modele,
            })
          "
          :is-fetching="fetchingModels"
          cy="filters-model"
          @select="handleSetFilter"
        />
      </div>
      <div class="flex flex-between gap-8">
        <BaseSelect
          name="an_de_la"
          placeholder="An incepand cu"
          :options="options.ani"
          :value="
            getSelectValue({
              value: filters.temp.an_de_la,
              options: options.ani,
            })
          "
          cy="filter-an-from"
          @select="handleYearFilter"
        />
        <BaseSelect
          name="an_pana_la"
          placeholder="An pana la"
          :options="yearsUpToOptions"
          :value="
            getSelectValue({
              value: filters.temp.an_pana_la,
              options: yearsUpToOptions,
            })
          "
          cy="filter-an-up-to"
          @select="handleSetFilter"
        />
      </div>
      <div class="pret-wrapper">
        <BaseInput
          name="pret_de_la"
          placeholder="pret minim"
          :value="filters.temp.pret_de_la"
          :stacked="false"
          cy="filter-pret-from"
          @input="handleSetFilter"
        />
        <BaseInput
          name="pret_pana_la"
          placeholder="pret maxim"
          :value="filters.temp.pret_pana_la"
          :stacked="false"
          cy="filter-pret-up-to"
          @input="handleSetFilter"
        />
        <button
          :class="{
            'selected-currency': filters.temp.currency === 'euro',
          }"
          class="bg-white"
          @click="handleSetFilter({ name: 'currency', value: 'euro' })"
        >
          €
        </button>
        <button
          :class="{
            'selected-currency': filters.temp.currency === 'lei',
          }"
          class="bg-white"
          @click="handleSetFilter({ name: 'currency', value: 'lei' })"
        >
          lei
        </button>
      </div>
      <div class="locatie-wrapper flex flex-between gap-8">
        <BaseSelect
          name="judet"
          placeholder="Judet"
          :options="options.judete"
          :value="
            getSelectValue({
              value: filters.temp.judet,
              options: options.judete,
            })
          "
          cy="filter-judet"
          @select="handleCountyFilter"
        />
        <BaseSelect
          name="oras"
          placeholder="Oras"
          :options="options.orase"
          :is-fetching="fetchingCities"
          :value="
            getSelectValue({
              value: filters.temp.oras,
              isFetching: fetchingCities,
              options: options.orase,
            })
          "
          cy="filter-oras"
          @select="handleSetFilter"
        />
      </div>
      <div class="actions-wrapper flex flex-around">
        <button
          class="reset-btn"
          cy="reset-btn"
          :disabled="isTempDefault && !hasActiveFilters"
          @click="resetFilters"
        >
          Resetează {{ isFetching && !isApplying ? "loading" : "" }}
        </button>
        <button
          class="apply-btn"
          cy="apply-btn"
          :disabled="isTempDefault && !hasActiveFilters"
          @click="applyFilters"
        >
          Aplică {{ isFetching && isApplying ? "loading" : "" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useWindowSize } from "@vueuse/core";
import { useRoute } from "vue-router";
import { getSelectValue } from "@/utils";
import { useUpdateRouteQuery } from "@/utils/composables";

defineProps({
  isFetching: {
    type: Boolean,
    default: false,
  },
});

onBeforeMount(() => {
  !options.value.marci.length && dispatch("get_marci");
  !options.value.judete.length && dispatch("get_judete");

  commit("SET_PIESE_FILTERS", {
    status: "temp",
    value: { ...filters.value.current },
  });
});

onBeforeUnmount(() => {
  commit("SET_PIESE_FILTERS", { status: "temp", setDefault: true });
  commit("SET_ROOT_FILTERS_OPTIONS", { key: "modele", value: [] });
  commit("SET_ROOT_FILTERS_OPTIONS", { key: "orase", value: [] });
});

const { updateQueryParams, resetQueryParams } = useUpdateRouteQuery();
const { state, dispatch, commit, getters } = useStore();
const { width } = useWindowSize();
const route = useRoute();

const emit = defineEmits(["hide"]);

const isApplying = ref(true);
const options = computed(() => state.options);
const filters = computed(() => state.piese.filters);
const fetchingModels = computed(() => state.is_fetching_modele);
const fetchingCities = computed(() => state.is_fetching_orase);
const currentPage = computed(() => state.piese.current_page);
const isTempDefault = computed(() =>
  getters.has_default_filters({ status: "temp" }),
);
const hasActiveFilters = computed(
  () => !getters.has_default_filters({ status: "current" }),
);
const yearsUpToOptions = computed(() =>
  options.value.ani.slice(
    0,
    options.value.ani.indexOf(+filters.value.temp.an_de_la) + 1,
  ),
);

function hide() {
  width.value >= 1024
    ? commit("SET_ROOT_STATE", { key: "is_modal", value: false })
    : emit("hide");
}

async function resetFilters() {
  if (hasActiveFilters.value) {
    isApplying.value = false;

    commit("SET_PIESE_STATE", { key: "current_page", value: "1" });
    commit("SET_PIESE_STATE", { key: "bookmarks", value: [] });
    commit("SET_PIESE_FILTERS", { status: "current", setDefault: true });

    await resetQueryParams({
      query: route.query,
      page: currentPage.value,
      removeQueries: Object.keys(filters.value.current),
    });

    commit("SET_PIESE_STATE", {
      key: "currency",
      value: filters.value.current.currency,
    });

    return hide();
  }

  commit("SET_PIESE_FILTERS", { status: "temp", setDefault: true });
}

async function applyFilters() {
  isApplying.value = true;

  commit("SET_PIESE_FILTERS", {
    status: "current",
    value: { ...filters.value.temp },
  });
  commit("SET_PIESE_STATE", { key: "bookmarks", value: [] });
  commit("SET_PIESE_STATE", { key: "current_page", value: "1" });

  await updateQueryParams({
    query: {
      ...route.query,
      ...filters.value.current,
    },
  });

  commit("SET_PIESE_STATE", {
    key: "currency",
    value: filters.value.current.currency,
  });

  hide();
}

function handleBrandFilter(pair) {
  handleSetFilter(pair);
  handleSetFilter({
    name: "model",
    value: null,
  });
  dispatch("get_modele", { marca: pair.value });
}

function handleCountyFilter(pair) {
  handleSetFilter(pair);
  handleSetFilter({
    name: "oras",
    value: null,
  });
  dispatch("get_orase", { judet: pair.value });
}

function handleYearFilter(pair) {
  handleSetFilter(pair);

  if (filters.value.current.an_de_la > filters.value.current.an_pana_la) {
    handleSetFilter({
      name: "an_pana_la",
      value: filters.value.current.value.an_de_la,
    });
  }
}

function handleSetFilter({ name, value }) {
  commit("SET_PIESE_FILTERS", { status: "temp", key: name, value });
}
</script>

<style lang="scss">
.base-filters {
  transform: translateY(-100%);
  border-top: 1px solid gray;
  position: absolute;
  width: 100%;
  padding: 12px;
  background-color: #e8e8e8;

  @media only screen and (min-width: 1024px) {
    position: initial;
    border-top: none;
    transform: none;
  }

  &.v-enter-active,
  &.v-leave-active {
    transition: all 0.3s ease;
  }

  &.v-enter,
  &.v-leave-to {
    transform: translateY(50%);
  }

  .content-wrapper {
    width: 100%;
    max-width: 656px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  span {
    margin: 4px 4px 0 0;
    font-size: 1.6rem;
    display: inline-block;
    white-space: nowrap;
  }

  .pret-wrapper {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    gap: 8px;
    height: 38px;

    @media only screen and (min-width: 500px) {
      grid-template-columns: repeat(4, 1fr);
    }

    input {
      height: 100%;
    }

    button {
      height: 28px;
      padding: 0 8px;
      border: 1px solid lightgray;
      flex: 1;
      height: 100%;
    }
  }

  .locatie-wrapper {
    align-items: center;
  }

  .selected-currency {
    background-color: green;
    color: white;
  }

  .actions-wrapper {
    margin-top: 10px;
    gap: 10px;

    .apply-btn {
      background-color: green;
      color: white;
      width: 100%;
    }

    .reset-btn {
      background-color: white;
      border: 1px solid green;
      width: 100%;
    }
  }
}
</style>
