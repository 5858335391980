<template>
  <div class="file-upload relative">
    <label v-if="!preview" class="flex-center relative wh100" :for="name">
      <img src="/upload-icon.svg" />
      <input :id="name" class="absolute" type="file" @change="handleFile" />
    </label>
    <figure v-if="preview" class="flex-center wh100">
      <img
        ref="previewElm"
        style="width: 200px"
        :src="`/imgs/${id}/${image}`"
        alt=""
        class="w100"
      />
    </figure>
    <button v-if="preview" class="remove absolute" @click="handleRemove">
      <img src="/remove-icon.svg" />
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  image: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["upload", "remove"]);

const preview = ref(!!props.id && !!props.image);
const previewElm = ref(null);

function handleFile(input) {
  preview.value = true;
  emit("upload", { name: props.name, file: input.target.files[0] });
  previewImage(input);
}

function previewImage(input) {
  const reader = new FileReader();
  reader.readAsDataURL(input.target.files[0]);
  reader.onload = function (e) {
    previewElm.value.setAttribute("src", e.target.result);
  };
}

function handleRemove() {
  preview.value = false;
  emit("remove", { name: props.name });
  previewElm.value.setAttribute("src", "");
}
</script>

<style lang="scss">
.file-upload {
  min-width: 120px;
  width: 100%;
  height: 120px;

  label {
    border: 1px solid gray;
    border-radius: 4px;
    background-color: lightgray;
    cursor: pointer;
  }
  input {
    visibility: hidden;
    width: 0;
    height: 0.1px;
  }
  figure {
    overflow: hidden;
    border: 1px solid gray;
    border-radius: 4px;
  }
  > button {
    top: -14px;
    right: -14px;
    z-index: 9;
    background: none;
  }
}
</style>
