import axios from "axios";
import api from "../api";
import router from "@/router";
import { getQueryParamString } from "@/utils";

export const state = {
  data: [],
  bookmarks: [],
  cerere: {},
  is_fetching: false,
  finished_fetching: false,
  is_search_result: false,
  has_next_page: true,
  current_page: "1",
};

export const mutations = {
  SET_CERERI_STATE(state, { key, value }) {
    state[key] = value;
  },
  SET_FETCHING(state, options = {}) {
    const { type, init = true } = options;
    switch (type) {
      default:
        state.is_fetching = init;
        state.finished_fetching = !init;
    }
  },
  SET_CERERI(state, data) {
    state.data = data.map((piesa) => ({
      ...piesa,
      id: piesa._id || piesa.id,
    }));
  },
  SAVE_CERERE(state, data) {
    state.data.push(data);
  },
  SET_CERERE_FIELD(state, { key, value }) {
    state.cerere[key] = value;
  },
  REMOVE_CERERE(state, { id, rootState }) {
    const index = state.data.findIndex((o) => o.id === id);
    const indexAnunt = rootState.profile.user_profile.cereri?.findIndex(
      (o) => o.id === id,
    );
    state.data.splice(index, 1);
    rootState.profile.user_profile.cereri.splice(indexAnunt, 1);
  },
  UPDATE_CERERE(state, cerere) {
    const index = state.data.findIndex((o) => o.id === cerere.id);
    state.data[index] = cerere;
  },
};

export const actions = {
  async get_cereri_piese({ commit }, params) {
    try {
      commit("SET_FETCHING");
      const query = getQueryParamString(params);
      const { data } = await axios.get(`${api.cereri}${query}`);

      commit("SET_CERERI", data.docs);
      commit("SET_CERERI_STATE", { key: "has_next_page", value: data.more });
      commit("SET_CERERI_STATE", { key: "is_search_result", value: false });
      commit("SET_FETCHING", { init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { init: false });
    }
  },
  async save_cerere_noua({ state, commit, dispatch, rootState }) {
    try {
      const { oras, judet, telefon } = rootState.profile.user_profile;
      commit("SET_CERERE_FIELD", { key: "oras", value: oras });
      commit("SET_CERERE_FIELD", { key: "judet", value: judet });
      commit("SET_CERERE_FIELD", { key: "telefon", value: telefon });
      const { data } = await axios.post(api.cereri, state.cerere);
      commit("SAVE_CERERE", data);
      await dispatch("get_profil");
      await router.push("/cereri-piese");
    } catch (err) {
      console.log(err);
    }
  },
  async update_cerere({ state, commit, dispatch }) {
    try {
      const { data } = await axios.put(
        `${api.cereri}/${state.cerere.id}`,
        state.cerere,
      );
      commit("UPDATE_CERERE", data);
      await dispatch("get_profil");
      await router.push("/profil/anunturi");
    } catch (err) {
      console.log(err);
    }
  },
  async delete_cerere({ commit, rootState }, id) {
    try {
      commit("SET_FETCHING");
      await axios.delete(`${api.cereri}/${id}`);
      commit("REMOVE_CERERE", { id, rootState });
      commit("SET_FETCHING", { init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { init: false });
    }
  },
  async get_detalii_cerere({ commit }, id) {
    try {
      commit("SET_FETCHING");
      const { data } = await axios(`${api.cereri}/${id}`);
      commit("SET_CERERI_STATE", { key: "cerere", value: { ...data } });
      commit("SET_FETCHING", { init: false });
    } catch (e) {
      commit("SET_FETCHING", { init: false });
    }
  },
  async cauta_cerere({ commit }, params) {
    try {
      commit("SET_FETCHING");
      const query = getQueryParamString(params);
      const { data } = await axios(`${api.cauta_cerere}${query}`);

      commit("SET_CERERI", data.docs);
      commit("SET_CERERI_STATE", { key: "is_search_result", value: true });
      commit("SET_CERERI_STATE", {
        key: "has_next_page",
        value: data.bookmark ? true : false,
      });
      commit("SET_CERERI_STATE", {
        key: "bookmarks",
        value: [...state.bookmarks, data.bookmark],
      });
      commit("SET_FETCHING", { init: false });
    } catch (err) {
      commit("SET_FETCHING", { init: false });
      console.log(err);
    }
  },

  // TODO
  async send_cerere_message(store, { id, message }) {
    try {
      // await axios.post(`${api.cerere}/${id}/message`, {
      //   message,
      // });
    } catch (err) {
      console.log(err);
    }
  },
};
