<template>
  <div class="base-confirmation">
    <p class="text-center">{{ message }}</p>
    <div v-if="actions" class="flex flex-between">
      <button cy="confirm" @click="handleAction('da')">Da</button>
      <button cy="cancel" @click="handleAction('nu')">Nu</button>
    </div>
    <button v-else @click="handleClose">OK</button>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const { state, commit } = useStore();

const message = computed(() => state.confirmation.message);
const actions = computed(() => state.confirmation.actions);
const confirm = computed(() => state.confirmation.confirm);
const cancel = computed(() => state.confirmation.cancel);

function handleAction(action) {
  if (action === "da") {
    confirm.value();
  }
  if (action === "nu") {
    cancel.value();
    handleClose();
  }
}

function handleClose() {
  commit("SET_ROOT_STATE", { key: "is_modal", value: false });
}
</script>

<style lang="scss"></style>
