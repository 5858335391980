<template>
  <div class="base-sort flex flex-center" cy="base-sort">
    <div class="content-wrapper">
      <h3>Sortare</h3>
      <BaseSelect
        name="sort"
        placeholder="Sortare dupa"
        :options="options.sorting"
        :value="
          getSelectValue({
            value: sorting.temp?.sort,
            options: options.sorting,
          })
        "
        cy="sorting"
        @select="handleSetSort"
      />

      <div class="actions-wrapper flex flex-around">
        <button
          class="reset-btn"
          cy="reset-btn"
          :disabled="!sorting.temp.sort"
          @click="resetSort"
        >
          Resetează {{ isFetching && !isApplying ? "loading" : "" }}
        </button>
        <button
          class="apply-btn"
          cy="apply-btn"
          :disabled="!sorting.temp.sort"
          @click="applySort"
        >
          Aplică {{ isFetching && isApplying ? "loading" : "" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useWindowSize } from "@vueuse/core";
import { useRoute } from "vue-router";
import { getSelectValue } from "@/utils";
import { useUpdateRouteQuery } from "@/utils/composables";

defineProps({
  isFetching: {
    type: Boolean,
    default: false,
  },
});

onBeforeMount(() => {
  commit("SET_PIESE_SORTING", {
    status: "temp",
    value: { ...sorting.value.current },
  });
});

onBeforeUnmount(() => {
  commit("SET_PIESE_SORTING", { status: "temp", setDefault: true });
});

const { updateQueryParams, resetQueryParams } = useUpdateRouteQuery();
const { state, commit, getters } = useStore();
const { width } = useWindowSize();
const route = useRoute();

const emit = defineEmits(["hide"]);

const isApplying = ref(true);
const options = computed(() => state.options);
const currentPage = computed(() => state.piese.current_page);
const sorting = computed(() => state.piese.sorting);
const hasDefaultSorting = computed(() => getters.has_default_sorting);

function hide() {
  width.value >= 1024
    ? commit("SET_ROOT_STATE", { key: "is_modal", value: false })
    : emit("hide");
}

async function resetSort() {
  if (!hasDefaultSorting.value) {
    isApplying.value = false;

    commit("SET_PIESE_STATE", { key: "current_page", value: "1" });
    commit("SET_PIESE_STATE", { key: "bookmarks", value: [] });
    commit("SET_PIESE_SORTING", { status: "current", setDefault: true });

    await resetQueryParams({
      query: route.query,
      page: currentPage.value,
      removeQueries: Object.keys(sorting.value.current),
    });

    return hide();
  }

  commit("SET_PIESE_SORTING", { status: "temp", setDefault: true });
}

async function applySort() {
  isApplying.value = true;

  commit("SET_PIESE_SORTING", {
    status: "current",
    value: { ...sorting.value.temp },
  });
  commit("SET_PIESE_STATE", { key: "bookmarks", value: [] });
  commit("SET_PIESE_STATE", { key: "current_page", value: "1" });

  await updateQueryParams({
    query: {
      ...route.query,
      ...sorting.value.current,
    },
  });

  hide();
}

function handleSetSort({ value }) {
  commit("SET_PIESE_SORTING", { status: "temp", key: "sort", value });
}
</script>

<style lang="scss">
.base-sort {
  transform: translateY(-100%);
  border-top: 1px solid gray;
  position: absolute;
  width: 100%;
  padding: 12px;
  background-color: #e8e8e8;

  @media only screen and (min-width: 1024px) {
    position: initial;
    border-top: none;
    transform: none;
  }

  &.v-enter-active,
  &.v-leave-active {
    transition: all 0.3s ease;
  }

  &.v-enter,
  &.v-leave-to {
    transform: translateY(50%);
    opacity: 0;
  }

  .content-wrapper {
    width: 100%;
    max-width: 656px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  span {
    margin: 4px 4px 0 0;
    font-size: 1.6rem;
    display: inline-block;
    white-space: nowrap;
  }

  .actions-wrapper {
    margin-top: 10px;
    gap: 10px;

    .apply-btn {
      background-color: green;
      color: white;
      width: 100%;
    }

    .reset-btn {
      background-color: white;
      border: 1px solid green;
      width: 100%;
    }
  }
}
</style>
