export function debounce(func, wait) {
  let timeout;
  return () => {
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      func.apply(this, args);
    }, wait);
  };
}

export function limitStringWithEllipsis({ inputString, maxLength = 14 }) {
  if (typeof inputString !== "string") {
    throw new Error("Invalid input. Please provide a valid string.");
  }

  if (inputString.length <= maxLength) {
    return inputString;
  }

  return inputString.substring(0, maxLength - 3) + "...";
}

export function capitalize(inputString) {
  if (typeof inputString !== "string") {
    throw new Error("Invalid input. Please provide a valid string.");
  }

  return inputString.slice(0, 1).toUpperCase() + inputString.slice(1);
}

export function getInitials(firstName, lastName) {
  if (!firstName) throw new Error("firstName is required");
  if (!lastName) return capitalize(firstName?.slice(0, 1));

  return `${capitalize(firstName?.slice(0, 1))}${capitalize(
    lastName?.slice(0, 1),
  )}`;
}
