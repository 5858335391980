import { useStore } from "vuex";
import { debounce } from "./helpers";
import { onMounted, onUnmounted } from "vue";
import router from "@/router";
import { getValidQueries, removeObjProps } from "./index";

export function useRegularFetch({ dispatchGet, commitSet, stateModule }) {
  const { state, commit, dispatch } = useStore();

  function handleStateUpdate({ query }) {
    query.page && commit(commitSet, { key: "current_page", value: query.page });
    query.limit && commit("ROOT_SET_PAGINATION_LIMIT", query.limit);
    if (query.currency) {
      commit("SET_PIESE_STATE", { key: "currency", value: query.currency });
      commit("SET_PIESE_FILTERS", {
        status: "current",
        key: "currency",
        value: query.currency,
      });
    }
  }

  async function handleRegularFetch({ query, isRouteUpdate }) {
    if (!Object.keys(query).length || query.page) {
      !isRouteUpdate && handleStateUpdate({ query });

      await dispatch(dispatchGet, {
        ...query,
        page: state[stateModule].current_page,
        limit: state.pagination_limit,
      });
      return true;
    }
    return false;
  }

  return { handleRegularFetch };
}

export function useSearchFetch({ dispatchSearch }) {
  const { state, commit, dispatch } = useStore();

  function handlePieseStateUpdate({ filters, sort }) {
    const filtersEntries = Object.entries(filters);

    if (filtersEntries.length) {
      filtersEntries.forEach(([key, value]) =>
        commit("SET_PIESE_FILTERS", {
          status: "current",
          key,
          value,
        }),
      );

      filters.marca && dispatch("get_modele", { marca: filters.marca });
      filters.judet && dispatch("get_orase", { judet: filters.judet });
      filters.currency &&
        commit("SET_PIESE_STATE", {
          key: "currency",
          value: filters.currency,
        });
    }

    sort && commit("SET_PIESE_SORTING", { status: "current", value: { sort } });
  }

  async function handleSearchFetch({ query, isRouteUpdate, isPieseView }) {
    const { search, limit, bookmark, sort, ...filters } = query;

    if (!isRouteUpdate) {
      isPieseView && handlePieseStateUpdate({ filters, sort });
      if (search) commit("ROOT_SET_SEARCH_VALUE", search);
      if (limit) commit("ROOT_SET_PAGINATION_LIMIT", limit);
    }

    await dispatch(dispatchSearch, {
      ...query,
      bookmark,
      limit: state.pagination_limit,
    });
  }

  return { handleSearchFetch };
}

export function useCurrentPage({ commitSet, stateModule }) {
  const { commit, state } = useStore();

  function setCurrentPage({ page, isSearchResult, query }) {
    commit(commitSet, { key: "current_page", value: page });

    router.push({
      query: {
        ...getValidQueries({
          ...query,
          limit: state.pagination_limit,
          ...(isSearchResult.value
            ? { bookmark: state[stateModule].bookmarks[page - 2] }
            : { page }),
        }),
      },
    });
  }

  return { setCurrentPage };
}

export function useHandleReset({ commitSet, beforeRoutePush = () => {} }) {
  const { commit, state } = useStore();
  const { resetQueryParams } = useUpdateRouteQuery();

  function handleReset() {
    const page = "1";

    commit(commitSet, { key: "current_page", value: page });
    commit("ROOT_SET_SEARCH_VALUE", "");

    beforeRoutePush();

    resetQueryParams({
      query: {
        page,
        limit: state.pagination_limit,
      },
    });
  }

  return {
    handleReset,
  };
}

export function useUpdateRouteQuery() {
  const { state } = useStore();
  const globalPageLimit = state.pagination_limit;

  async function updateQuery(
    { query, page, removeQueries = [] },
    reset = false,
  ) {
    const params = removeObjProps({
      obj: query,
      propsToRemove: [
        "bookmark",
        ...(!reset ? ["page"] : []),
        ...removeQueries,
      ],
    });

    const shouldAddPageParam =
      reset &&
      Object.keys(
        removeObjProps({
          obj: params,
          propsToRemove: ["currency"],
        }),
      ).length <= 1;

    await router.push({
      query: {
        ...getValidQueries({
          ...params,
          ...(shouldAddPageParam ? { page } : {}),
          ...(!params.limit ? { limit: globalPageLimit } : {}),
        }),
      },
    });
  }

  return {
    updateQueryParams: (params) => updateQuery(params),
    resetQueryParams: (params) => updateQuery(params, true),
  };
}

export function useBottomScroll() {
  const { commit } = useStore();
  let lastScrollTop = 0;

  function handleScroll() {
    const currentScrollTop = window.scrollY;

    if (currentScrollTop > lastScrollTop) {
      commit("SET_ROOT_STATE", { key: "is_bottom_scroll", value: true });
    }
    if (currentScrollTop <= lastScrollTop) {
      commit("SET_ROOT_STATE", { key: "is_bottom_scroll", value: false });
    }

    lastScrollTop = currentScrollTop;
  }

  const debouncedHandleBottomScroll = debounce(handleScroll, 100);

  onMounted(() => {
    document.addEventListener("scroll", debouncedHandleBottomScroll);
  });

  onUnmounted(() => {
    document.removeEventListener("scroll", debouncedHandleBottomScroll);
  });
}
