import axios from "axios";
import api from "../api";
import router from "../../router";

export const state = {
  user: {},
  account: {
    logged_in: false,
    created: false,
    active: false,
    errors: false,
  },
  form: {},
  form_validation: {
    wrong_credentials: false,
    already_registered: false,
    invalid_email: false,
  },
  updated_favorites: false,
  is_fetching_password: false,
};

export const mutations = {
  SET_AUTH_STATE(state, { key, value }) {
    state[key] = value;
  },
  SET_AUTH_ACCOUNT(state, data) {
    state.account = { ...state.account, ...data };
  },
  SET_AUTH_VALIDATION(state, { key, value }) {
    state.form_validation[key] = value;
  },
  SET_AUTH_FORM_FIELD(state, { name, value }) {
    state.form[name] = value;
  },
  SET_UPDATED_FAVORITES(state, value) {
    state.update_favorites = value;
  },
  RESET_AUTH_FORM_FIELDS(state) {
    state.form = {};
  },
  RESET_AUTH_VALIDATION(state) {
    state.form_validation = {};
  },
};

export const actions = {
  async login({ commit, dispatch }, user) {
    try {
      const { data } = await axios.post(api.login, user);
      const localFavs = JSON.parse(localStorage.getItem("userFavorites"));
      localFavs?.length && (await dispatch("update_favorites", localFavs));

      await dispatch("get_profil");
      await router.push("/lista-piese");

      commit("SET_AUTH_STATE", { key: "user", value: data });
      commit("SET_AUTH_ACCOUNT", {
        active: data?.role !== "not-active",
        logged_in: !!data?.name,
      });

      data?.role === "not-active" &&
        commit("SET_AUTH_ACCOUNT", { status: "not-active" });
    } catch (err) {
      console.log(err);
      err.response.status === 401 &&
        commit("SET_AUTH_VALIDATION", {
          key: "wrong_credentials",
          value: true,
        });
      console.log(err);
    }
  },
  async logout({ commit }) {
    try {
      await axios.delete(api.logout);
      commit("SET_AUTH_STATE", { key: "account", value: {} });
      await router.push({ name: "login" });
      commit("SET_AUTH_STATE", { key: "user", value: {} });
      commit("ROOT_RESET_FAVORITES", {}, { root: true });
      commit("ROOT_RESET_PROFIL", {}, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  async get_user({ state, commit, dispatch }) {
    try {
      const { data: user } = await axios.get(api.check_login);
      commit("SET_AUTH_STATE", { key: "user", value: user });
      commit("SET_AUTH_ACCOUNT", {
        active: user?.role !== "not-active",
        logged_in: !!user?.name,
      });

      if (!state.account.active) {
        commit("SET_AUTH_ACCOUNT", { status: "not-active" });
        return { name: "login" };
      }

      await dispatch("get_profil");
    } catch (err) {
      return { name: "login" };
    }
  },
  async register({ commit }, user) {
    try {
      await axios.post(api.signup, user);
      commit("SET_AUTH_ACCOUNT", { created: true });
      commit("SET_AUTH_ACCOUNT", { status: "created" });
    } catch (err) {
      err.response.status === 409 &&
        commit("SET_AUTH_VALIDATION", {
          key: "already_registered",
          value: true,
        });
      console.log(err);
    }
  },
  async confirm_account({ commit }, { id, email }) {
    try {
      commit("SET_AUTH_ACCOUNT", { active: false });
      await axios.post(`${api.confirm_account}/${id}/${email}`);
      commit("SET_AUTH_ACCOUNT", { active: true });
    } catch (err) {
      commit("SET_AUTH_ACCOUNT", { errors: true });
      console.log(err);
    }
  },
  async delete_account({ commit }) {
    try {
      await axios.delete(api.delete_account);
      commit("SET_AUTH_STATE", { key: "user", value: {} });
      commit("SET_AUTH_STATE", { key: "account", value: {} });
      commit("ROOT_RESET_FAVORITES", {}, { root: true });
      commit("ROOT_RESET_PROFIL", {}, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  async change_password({ commit }, password) {
    try {
      commit("SET_AUTH_STATE", { key: "is_fetching_password", value: true });
      await axios.post(api.reset_pass, { password });
      commit("SET_AUTH_STATE", {
        key: "is_fetching_password",
        value: false,
      });
    } catch (err) {
      console.log(err);
      commit("SET_AUTH_STATE", {
        key: "is_fetching_password",
        value: false,
      });
    }
  },
  async send_reset_pass_email({ commit }, { email }) {
    try {
      commit("SET_AUTH_STATE", { key: "is_fetching_password", value: true });
      await axios.post(api.forgot_pass, { email });
      commit("SET_AUTH_ACCOUNT", { status: "emailSent" });
      commit("SET_AUTH_STATE", {
        key: "is_fetching_password",
        value: false,
      });
    } catch (err) {
      console.log(err);
      err.response.status === 404 &&
        commit("SET_AUTH_VALIDATION", {
          key: "invalid_email",
          value: true,
        });
      commit("SET_AUTH_STATE", {
        key: "is_fetching_password",
        value: false,
      });
    }
  },
  async reset_password({ commit }, { password, query }) {
    try {
      commit("SET_AUTH_STATE", {
        key: "is_fetching_password",
        value: true,
      });
      await axios.post(`${api.reset_pass}/${query.id}/${query.email}`, {
        password,
      });
      commit("SET_AUTH_ACCOUNT", { status: "passwordChanged" });
      commit("SET_AUTH_STATE", {
        key: "is_fetching_password",
        value: false,
      });
    } catch (err) {
      console.log(err);
      err.response.status === 401 &&
        commit("SET_AUTH_ACCOUNT", { status: "unauthorized" });
      commit("SET_AUTH_STATE", {
        key: "is_fetching_password",
        value: false,
      });
    }
  },
  async update_favorites({ commit }, favorites) {
    try {
      const ids = favorites.map((favorite) => favorite.id);
      await axios.put(api.favorites_login, {
        ids,
      });
      commit("SET_UPDATED_FAVORITES", true);
    } catch (err) {
      console.log(err);
      commit("SET_UPDATED_FAVORITES", false);
    }
  },
};
