<template>
  <div class="base-select block flex" :class="{ stacked }">
    <label v-if="label" :for="name">
      {{ label }}
    </label>
    <select
      :id="name"
      :value="value"
      :cy="cy"
      :disabled="disabled"
      @change="handleSelect"
    >
      <option v-if="isFetching" value="isFetching">fetching</option>
      <template v-else>
        <option value="placeholder" selected disabled>{{ placeholder }}</option>
        <option
          v-for="(item, index) in options"
          :key="index"
          :value="item?.value ?? item"
        >
          {{ item?.text ?? item }}
        </option>
      </template>
    </select>
    <span v-show="validate" cy="validation">{{ validationMsgs[rule] }}</span>
  </div>
</template>

<script setup>
const props = defineProps({
  validate: Boolean,
  options: {
    type: Array,
    required: true,
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: null,
  },
  rule: {
    type: String,
    default: "",
  },
  cy: {
    type: String,
    default: null,
  },
  stacked: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["select"]);

const validationMsgs = {
  required: "Acest camp este necesar",
};

function handleSelect(ev) {
  emit("select", { name: props.name, value: ev.target.value });
}
</script>

<style lang="scss">
@import "../styles/vars";

.base-select {
  font-size: 1.6rem;
  align-items: center;
  gap: 8px;
  width: 100%;

  &.stacked {
    flex-direction: column;
    align-items: flex-start;

    select {
      flex: initial;
    }
  }

  label {
    white-space: nowrap;
  }

  select {
    width: 100%;
    height: 38px;
    padding-left: 8px;
    font-size: 1.6rem;
    color: black;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 4px;
  }

  > span:last-child {
    display: inline-block;
    margin-top: 4px;
    font-size: 1.4rem;
    color: $red;
  }
}
</style>
