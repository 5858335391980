<template>
  <div class="piesa-mesaj">
    <form @submit.prevent="handleSubmit">
      <BaseInput
        :rule="messageValidation"
        :value="message"
        :disabled="!isLoggedIn"
        type="textarea"
        name="piesa-message"
        placeholder="Trimite un mesaj"
        validate
        @input="handleInput"
      />
      <button :disabled="!isLoggedIn">
        Trimite {{ isSending ? "loading" : "" }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

const props = defineProps({
  dispatchAction: {
    type: String,
    required: true,
  },
});

const { id } = router.currentRoute.value.params;
const { state, dispatch } = useStore();

onBeforeMount(async () => {
  if (!isLoggedIn.value) messageValidation.value = "unauthorized";
});

const messageValidation = ref(null);
const message = ref("");
const isSending = ref(false);
const isLoggedIn = computed(() => state.auth.account.logged_in);

function handleInput({ value }) {
  message.value = value;
  messageValidation.value = null;
}

async function handleSubmit() {
  const trimmedMessage = message.value.trim();

  if (!trimmedMessage.length) {
    messageValidation.value = "required";
  }

  if (trimmedMessage.length) {
    isSending.value = true;

    await dispatch(props.dispatchAction, {
      id,
      message: message.value,
    });

    messageValidation.value = "success";
    isSending.value = false;
    message.value = "";
  }
}
</script>

<style lang="scss">
.piesa-mesaj {
  margin-top: 16px;
  grid-column-start: 1;
  grid-row-start: 6;

  @media only screen and (min-width: 1024px) {
    grid-row-start: 6;
  }

  form {
    width: 100%;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  .base-input {
    width: 100%;
  }

  textarea {
    resize: none;
    height: 124px;
  }

  button {
    width: fit-content;
    margin-top: 8px;
  }
}
</style>
