<template>
  <button class="log-out flex pointer" @click="handleLogout">
    <span v-if="showText">Logout</span>
    <img src="/logout-icon.svg" />
  </button>
</template>

<script setup>
import { useStore } from "vuex";

defineProps({
  showText: {
    type: Boolean,
    default: false,
  },
});

const { dispatch } = useStore();

function handleLogout() {
  dispatch("logout");
}
</script>

<style lang="scss">
.log-out {
  background: none;
  padding: 0;

  span {
    margin-right: 8px;
  }
}
</style>
