<template>
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.5" cy="15.5" r="15.5" fill="white" />
    <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
      <path
        d="M18 24.88C18.04 25.18 17.94 25.5 17.71 25.71C17.6175 25.8027 17.5076 25.8762 17.3866 25.9264C17.2657 25.9766 17.136 26.0024 17.005 26.0024C16.874 26.0024 16.7444 25.9766 16.6234 25.9264C16.5024 25.8762 16.3925 25.8027 16.3 25.71L12.29 21.7C12.1809 21.5934 12.098 21.463 12.0477 21.319C11.9974 21.175 11.9811 21.0213 12 20.87V15.75L7.21001 9.62C7.04762 9.41153 6.97434 9.14726 7.0062 8.88493C7.03805 8.6226 7.17244 8.38355 7.38001 8.22C7.57001 8.08 7.78001 8 8.00001 8H22C22.22 8 22.43 8.08 22.62 8.22C22.8276 8.38355 22.962 8.6226 22.9938 8.88493C23.0257 9.14726 22.9524 9.41153 22.79 9.62L18 15.75V24.88ZM10.04 10L14 15.06V20.58L16 22.58V15.05L19.96 10H10.04Z"
        fill="black"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FilterIcon",
};
</script>
