<template>
  <table class="tabel-piese" aria-label="Table of invoices">
    <thead>
      <tr>
        <th
          v-for="heading in Object.keys(data[0])"
          :key="heading"
          scope="col"
          height="40"
        >
          {{ capitalize(heading) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(piesa, index) in data" :key="index">
        <tr @click="handleSelect(piesa.id)">
          <td
            v-for="([key, value], piesaIndex) in Object.entries(piesa)"
            :key="piesaIndex"
            height="50"
            :title="value"
          >
            <template v-if="typeof value === 'string'">
              {{
                limitStringWithEllipsis({
                  inputString: value,
                  maxLength: piesaIndex === 0 ? 6 : undefined,
                })
              }}
            </template>
            <template v-else-if="key === 'pret'"
              >{{ value }} {{ currency }}</template
            >
            <div v-else-if="key === 'foto'">
              <figure>
                <img :src="`/imgs/${piesa['id']}/${value[0]}`" alt="" />
              </figure>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script setup>
import { limitStringWithEllipsis, capitalize } from "@/utils/helpers";

defineProps({
  data: {
    type: Array,
    required: true,
  },
  favorites: {
    type: Set,
    default: new Set(),
  },
  currency: {
    type: String,
    default: "lei",
  },
});

const emit = defineEmits(["select"]);

function handleSelect(id) {
  emit("select", id);
}
</script>

<style lang="scss">
.tabel-piese {
  display: none;

  @media only screen and (min-width: 1024px) {
    display: table;
    border-collapse: collapse;
    font-size: 16px;
    width: 100%;
    table-layout: fixed;

    thead {
      border-bottom: solid 1px grey;

      th {
        font-weight: 500;
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: lightgrey;
        }

        td {
          text-align: center;
          width: 25%;

          div {
            display: flex;
            justify-content: center;
            align-items: center;

            figure {
              max-width: 40px;
              max-height: 40px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
