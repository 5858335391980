<template>
  <div
    class="tabel-piese-actions"
    :class="{ 'cereri-variant': variant === 'cereri' }"
  >
    <div v-if="variant === 'piese'" class="filters">
      <SortFilterButton
        :show-text="true"
        :button-text="'Filtre'"
        :has-active-conditions="hasActiveFilters"
        :disabled="shouldDisableActions"
        @click="showFiltersModal"
      >
        <FiltersIcon />
      </SortFilterButton>
      <BaseModal>
        <BaseFilters :is-fetching="isFetching" />
      </BaseModal>
      <BaseSelect
        placeholder="Sortare dupa"
        name="sort"
        :disabled="shouldDisableActions"
        :options="options.sorting"
        :value="
          getSelectValue({
            value: sorting.current.sort,
            options: options.sorting,
          })
        "
        @select="handleSetSort"
      />
    </div>
    <SearchInput :variant="variant" :disabled="shouldDisableActions" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getSelectValue } from "@/utils";
import { useUpdateRouteQuery } from "@/utils/composables";
import SearchInput from "./SearchInput.vue";
import BaseFilters from "./BaseFilters.vue";
import SortFilterButton from "./SortFilterButton.vue";
import FiltersIcon from "./icons/FiltersIcon.vue";

defineProps({
  variant: {
    type: String,
    required: true,
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
  shouldDisableActions: {
    type: Boolean,
    default: false,
  },
});

const { updateQueryParams } = useUpdateRouteQuery();
const { state, commit, getters } = useStore();
const route = useRoute();

const sorting = computed(() => state.piese.sorting);
const options = computed(() => state.options);
const hasActiveFilters = computed(
  () => !getters.has_default_filters({ status: "current" }),
);

async function handleSetSort({ name, value }) {
  commit("SET_PIESE_SORTING", { status: "current", key: name, value });
  commit("SET_PIESE_STATE", { key: "bookmarks", value: [] });
  commit("SET_PIESE_STATE", { key: "current_page", value: "1" });

  updateQueryParams({
    query: {
      ...route.query,
      ...sorting.value.current,
    },
  });
}

function showFiltersModal() {
  commit("SET_ROOT_STATE", { key: "is_modal", value: true });
}
</script>

<style lang="scss">
.tabel-piese-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 38px;

  &.cereri-variant {
    justify-content: flex-end;
  }

  .filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 400px;
    width: 100%;
    gap: 8px;
  }

  .search-label {
    max-width: 400px;
  }
}
</style>
