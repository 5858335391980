import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        redirect: "/lista-piese",
      },
      {
        path: "/contact",
        name: "contact",
        meta: { title: "Contact" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
      },
      {
        path: "/register-success/:id/:email",
        name: "register-success",
        meta: { title: "Inregistare reusita" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/RegisterSuccessView.vue"
          ),
      },
      {
        path: "/lista-piese",
        name: "lista-piese",
        meta: { title: "Lista piese" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ListaPieseView.vue"),
      },
      {
        path: "/cereri-piese",
        name: "cereri-piese",
        meta: { title: "Lista cereri" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/CereriPieseView.vue"
          ),
      },
      {
        path: "/vinde-piesa",
        name: "vinde-piesa",
        meta: { protected: true, title: "Vinde piesa" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VindePiesaView.vue"),
      },
      {
        path: "/cerere-noua",
        name: "cerere-noua",
        meta: { protected: true, title: "Cerere noua" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/CerereNouaView.vue"),
      },
      {
        path: "/cerere/:id",
        name: "cerere",
        meta: { title: "cerere" },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/DetaliiCerereView.vue"
          ),
      },
      {
        path: "/favorite",
        name: "favorite",
        meta: { title: "Favorite" },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/FavoriteView.vue"),
      },
      {
        path: "/piesa/:id",
        name: "piesa",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/DetaliiPiesaView.vue"
          ),
      },
      {
        path: "/profil/",
        component: () => import("../layouts/ProfilLayout.vue"),
        children: [
          {
            path: "",
            redirect: "/profil/cont",
          },
          {
            path: "cont",
            name: "cont-profil",
            meta: { protected: true, title: "Cont | Profil", header: "Cont" },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/ProfilContView.vue"
              ),
          },
          {
            path: "anunturi",
            name: "anunturi-profil",
            meta: {
              protected: true,
              title: "Anunturi | Profil",
              header: "Anunturi",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/ProfilAnunturiView.vue"
              ),
          },
          {
            path: "setari",
            name: "setari-profil",
            meta: {
              protected: true,
              title: "Setari | Profil",
              header: "Setari",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/ProfilSetariView.vue"
              ),
          },
        ],
      },
    ],
  },

  {
    path: "/auth/",
    component: () => import("../layouts/AuthLayout.vue"),
    children: [
      {
        path: "login",
        name: "login",
        meta: { title: "Intra in cont", isAuthRoute: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/RegisterLoginView.vue"
          ),
      },
      {
        path: "register",
        name: "register",
        meta: { title: "Creeaza un cont", isAuthRoute: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/RegisterLoginView.vue"
          ),
      },
      {
        path: "reset-pass",
        name: "reset-pass",
        meta: { title: "Reseteaza parola", isAuthRoute: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResetPasswordView.vue"
          ),
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("../views/404View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

router.beforeEach(async ({ meta }) => {
  const { logged_in, active } = store.state.auth.account;
  document.title = meta.title || "Piese moto";

  if (meta?.isAuthRoute && logged_in && active) {
    return false;
  }

  if (meta?.protected && !active) {
    return logged_in ? { name: "login" } : store.dispatch("get_user");
  }
});

export default router;
