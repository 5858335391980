export function getYears(options = {}) {
  const { startYear = 1950 } = options;
  const currentYear = new Date().getFullYear();
  return Array.from(
    { length: currentYear - startYear + 1 },
    (value, index) => currentYear - index,
  );
}

export function getSelectValue({ value, isFetching, options }) {
  if (isFetching) return "isFetching";
  if (value && options.length) return value;
  return "placeholder";
}

export function getFieldsStatus({ form, values, validators }) {
  form.forEach(({ name, rule }) => {
    rule && (validators[name] = !values[name]);
  });

  return Object.keys(validators).every((key) => !validators[key]);
}

export function getValidQueries(obj) {
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key]) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export function getQueryParamString(obj) {
  const queryString = Object.entries(obj)
    .filter((entry) => entry[1])
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");

  return queryString ? `?${queryString}` : "";
}

export function removeObjProps({ obj, propsToRemove }) {
  if (!obj || !propsToRemove) {
    return obj;
  }

  const filteredObj = {};
  for (let key in obj) {
    if (!propsToRemove.includes(key)) {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
}
