import axios from "axios";
import api from "../api";
import router from "@/router";
import { getQueryParamString } from "@/utils";

const sortingDefault = {
  sort: null,
};

const filtersDefault = {
  currency: "lei",
  marca: null,
  model: null,
  an_de_la: null,
  an_pana_la: null,
  pret_de_la: null,
  pret_pana_la: null,
  judet: null,
  oras: null,
};

export const state = {
  data: [],
  images: [],
  piesa: {},
  bookmarks: [],
  favorite: new Map(),
  form_data: new FormData(),
  is_fetching: false,
  is_fetching_favorite: false,
  finished_fetching: false,
  finished_favorite_fetching: false,
  is_search_result: false,
  has_next_page: true,
  current_page: "1",
  currency: "lei",
  filters: {
    default: { ...filtersDefault },
    current: { ...filtersDefault },
    temp: {},
  },
  sorting: {
    default: { ...sortingDefault },
    current: { ...sortingDefault },
    temp: {},
  },
};

export const mutations = {
  SET_PIESE_STATE(state, { key, value }) {
    state[key] = value;
  },
  SET_FETCHING(state, options = {}) {
    const { type, init = true } = options;
    switch (type) {
      case "favorite":
        state.is_fetching_favorite = init;
        state.finished_favorite_fetching = !init;
        break;
      default:
        state.is_fetching = init;
        state.finished_fetching = !init;
    }
  },
  SET_PIESE_SORTING(state, { status, key, value, setDefault = false }) {
    if (setDefault) return (state.sorting[status] = { ...sortingDefault });
    if (key) return (state.sorting[status][key] = value);
    state.sorting[status] = value;
  },
  SET_PIESE_FILTERS(state, { status, key, value, setDefault = false }) {
    if (setDefault) return (state.filters[status] = { ...filtersDefault });
    if (key) return (state.filters[status][key] = value);
    state.filters[status] = value;
  },
  SET_PIESE(state, data) {
    state.data = data.map((piesa) => ({
      ...piesa,
      id: piesa._id || piesa.id,
    }));
  },
  SET_PIESE_FAVORITE(state, data) {
    data.forEach((favorite) => {
      const id = favorite?._id || favorite?.id;

      state.favorite.set(id, {
        ...favorite,
        id,
      });
    });
  },
  SAVE_PIESA(state, formData) {
    const piesa = {};
    for (const pair of formData.entries()) {
      piesa[pair[0]] = pair[1];
    }
    state.data.push(piesa);
  },
  APPEND_PIESE_FORMDATA(state, { key, value }) {
    state.form_data.append(key, JSON.stringify(value));
  },
  SET_PIESE_FORMDATA(state, data) {
    state.images = [...data.images];
    state.form_data = new FormData();
    Object.keys(data).forEach((key) => {
      key !== "images" && state.form_data.append(key, data[key]);
    });
  },
  UPDATE_PIESA(state, piesa) {
    const index = state.data.findIndex((o) => o.id === piesa.id);
    state.data[index] = piesa;
  },
  DELETE_PIESA_IMAGE(state, name) {
    state.images = state.images.map((image) => (image === name ? null : image));
  },
  REMOVE_PIESA(state, { id, rootState }) {
    const index = state.data.findIndex((o) => o.id === id);
    const indexAnunt = rootState.profile.user_profile.anunturi?.findIndex(
      (o) => o.id === id,
    );
    state.data.splice(index, 1);
    rootState.profile.user_profile.anunturi.splice(indexAnunt, 1);
  },
};

export const getters = {
  piese_favorite: ({ favorite }) => Array.from(favorite.values()),
  has_default_filters:
    ({ filters }) =>
    ({ status }) =>
      Object.values(filters[status]).every(
        (item, index) => item === Object.values(filters.default)[index],
      ),
  has_default_sorting: ({ sorting }) =>
    sorting.default?.sort === sorting.current?.sort,
};

export const actions = {
  async get_piese_vanzare({ commit }, params) {
    try {
      commit("SET_FETCHING");
      const query = getQueryParamString(params);
      const { data } = await axios.get(`${api.piese}${query}`);
      commit("SET_PIESE", data.docs);
      commit("SET_PIESE_STATE", { key: "has_next_page", value: data.more });
      commit("SET_PIESE_STATE", { key: "is_search_result", value: false });
      commit("SET_FETCHING", { init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { init: false });
    }
  },
  async get_piese_favorite({ commit }) {
    try {
      commit("SET_FETCHING", { type: "favorite" });
      const { data } = await axios.get(api.favorite);
      commit("SET_PIESE_FAVORITE", data);
      commit("SET_FETCHING", { type: "favorite", init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { type: "favorite", init: false });
    }
  },
  async add_favorite({ commit }, { id }) {
    try {
      await axios.put(api.favorite, { id });
      commit("ROOT_ADD_TO_FAVORITE", id, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  async remove_favorite({ commit }, { id }) {
    try {
      await axios.put(api.unfavorite, { id });
      commit("ROOT_REMOVE_FROM_FAVORITE", id, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  async save_piesa_noua({ state, commit, dispatch, rootState }) {
    try {
      state.form_data.append("createdAt", new Date());
      state.form_data.append("owner", rootState.auth.user.name);
      state.form_data.append("oras", rootState.profile.user_profile.oras);
      state.form_data.append("judet", rootState.profile.user_profile.judet);
      state.form_data.append("telefon", rootState.profile.user_profile.telefon);
      const { data } = await axios.post(api.piese, state.form_data);
      state.form_data.append("id", data.id);
      commit("SAVE_PIESA", state.form_data);
      await dispatch("get_profil");
      await router.push("/lista-piese");
    } catch (err) {
      console.log(err);
    }
  },
  async update_piesa({ state, commit, dispatch }) {
    try {
      const { data } = await axios.put(
        `${api.piesa}/${state.form_data.get("id")}`,
        state.form_data,
      );
      commit("UPDATE_PIESA", data);
      await dispatch("get_profil");
      await router.push("/profil/anunturi");
    } catch (err) {
      console.log(err);
    }
  },
  async get_detalii_piesa({ commit }, id) {
    try {
      commit("SET_FETCHING");
      const { data } = await axios(`${api.piesa}/${id}`);
      commit("SET_PIESE_STATE", { key: "piesa", value: data });
      commit("SET_FETCHING", { init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { init: false });
    }
  },
  async delete_anunt({ commit, rootState }, id) {
    try {
      commit("SET_FETCHING");
      await axios.delete(`${api.piesa}/${id}`);
      commit("REMOVE_PIESA", { id, rootState });
      commit("SET_FETCHING", { init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { init: false });
    }
  },
  async piesa_vanduta({ commit, rootState }, { id, vandutPrinPieseMoto }) {
    try {
      commit("SET_FETCHING");
      await axios.put(`${api.vandut}/${id}`, { vandutPrinPieseMoto });
      commit("REMOVE_PIESA", { id, rootState });
      commit("SET_FETCHING", { init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { init: false });
    }
  },
  async cauta_piesa({ state, commit }, params) {
    try {
      commit("SET_FETCHING");
      const query = getQueryParamString(params);
      const { data } = await axios(`${api.cauta_piesa}${query}`);

      commit("SET_PIESE", data.docs);
      commit("SET_PIESE_STATE", { key: "is_search_result", value: true });
      commit("SET_PIESE_STATE", {
        key: "has_next_page",
        value: data.bookmark ? true : false,
      });
      !state.bookmarks.includes(data.bookmark) &&
        commit("SET_PIESE_STATE", {
          key: "bookmarks",
          value: [...state.bookmarks, data.bookmark],
        });

      commit("SET_FETCHING", { init: false });
    } catch (err) {
      console.log(err);
      commit("SET_FETCHING", { init: false });
    }
  },
  async send_piesa_message(store, { id, message }) {
    try {
      await axios.post(`${api.piesa}/${id}/message`, {
        message,
      });
    } catch (err) {
      console.log(err);
    }
  },
};
