export const favoritesLocalStorage = (store) => {
  handleStoredFavorites(store.commit);

  store.subscribe(({ type }, { piese, auth }) => {
    const { isFavoritesMutation, isResetFavMutation } = getMutations(type);
    const isActive = auth.account.active;

    if (isActive) return;

    if (isFavoritesMutation) {
      const favorites = Array.from(piese.favorite.values());
      localStorage.setItem("userFavorites", JSON.stringify(favorites));
    }

    if (isResetFavMutation) {
      localStorage.removeItem("userFavorites");
    }
  });
};

const handleStoredFavorites = (commit) => {
  const storedFavorites = JSON.parse(localStorage.getItem("userFavorites"));

  if (storedFavorites) {
    commit("SET_PIESE_FAVORITE", storedFavorites);
    commit(
      "SET_PROFILE_FAVORITE",
      storedFavorites.map((fav) => fav.id),
    );
  }
};

const getMutations = (type) => {
  const isFavoritesMutation =
    type === "ROOT_ADD_TO_FAVORITE" ||
    type === "ROOT_REMOVE_FROM_FAVORITE" ||
    type === "SET_PIESE_FAVORITE";
  const isResetFavMutation =
    type === "ROOT_RESET_FAVORITES" || type === "SET_UPDATED_FAVORITES";

  return { isFavoritesMutation, isResetFavMutation };
};
