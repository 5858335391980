import axios from "axios";
import { createStore } from "vuex";
import * as auth from "./modules/auth";
import * as piese from "./modules/piese_vanzare";
import * as profile from "./modules/profile";
import * as cereri from "./modules/cereri_piese";
import api from "./api";
import { getYears } from "@/utils";
import { favoritesLocalStorage } from "./plugins/favoritesLocalStorage";

export default createStore({
  state: {
    is_modal: false,
    is_bottom_scroll: false,
    is_fetching_modele: false,
    is_fetching_orase: false,
    pagination_limit: "2",
    search_value: "",
    confirmation: {
      message: "",
      actions: false,
      confirm: () => {},
      cancel: () => {},
    },
    options: {
      sorting: [
        {
          value: "noi",
          text: "Cele mai noi",
        },
        {
          value: "vechi",
          text: "Cele mai vechi",
        },
        {
          value: "scump",
          text: "Cele mai scumpe",
        },
        {
          value: "ieftin",
          text: "Cele mai ieftine",
        },
      ],
      ani: getYears(),
      marci: [],
      modele: [],
      judete: [],
      orase: [],
      cilindree: ["500", "700"],
      stare: ["Folosit", "Nou"],
    },
  },
  mutations: {
    SET_ROOT_STATE(state, { key, value }) {
      state[key] = value;
    },
    SET_ROOT_CONFIRMATION(state, value) {
      state.confirmation = {
        ...state.confirmation,
        ...value,
      };
    },
    SET_ROOT_FILTERS_OPTIONS(state, { key, value }) {
      state.options[key] = value;
    },
    ROOT_ADD_TO_FAVORITE(state, id) {
      const piesa = state.piese.data?.find((piesa) => piesa.id === id);
      state.piese.favorite?.set(id, piesa);
      state.profile.user_profile.favorites?.add(id);
    },
    ROOT_REMOVE_FROM_FAVORITE(state, id) {
      state.piese.favorite?.delete(id);
      state.profile.user_profile.favorites?.delete(id);
    },
    ROOT_RESET_PROFIL(state) {
      state.profile = {
        ...state.profile,
        valid_profile: false,
        user_profile: {
          favorites: new Set(),
        },
      };
    },
    ROOT_RESET_FAVORITES(state) {
      state.piese.favorite = new Map();
    },
    ROOT_SET_SEARCH_VALUE(state, value) {
      state.search_value = value;
    },
    ROOT_SET_PAGINATION_LIMIT(state, value) {
      state.pagination_limit = value;
    },
  },
  actions: {
    async get_marci({ commit }) {
      try {
        const { data } = await axios.get(api.marci);
        commit("SET_ROOT_FILTERS_OPTIONS", { key: "marci", value: data });
      } catch (err) {
        console.log(err);
      }
    },
    async get_modele({ commit }, { marca }) {
      try {
        commit("SET_ROOT_STATE", { key: "is_fetching_modele", value: true });
        const { data } = await axios.get(`${api.marci}/${marca}`);
        commit("SET_ROOT_FILTERS_OPTIONS", { key: "modele", value: data });
        commit("SET_ROOT_STATE", { key: "is_fetching_modele", value: false });
      } catch (err) {
        console.log(err);
        commit("SET_ROOT_STATE", { key: "is_fetching_modele", value: false });
      }
    },
    async get_judete({ commit }) {
      try {
        const { data } = await axios.get(`${api.adrese}/judete`);
        commit("SET_ROOT_FILTERS_OPTIONS", { key: "judete", value: data });
      } catch (err) {
        console.log(err);
      }
    },
    async get_orase({ commit }, { judet }) {
      try {
        commit("SET_ROOT_STATE", { key: "is_fetching_orase", value: true });
        const { data } = await axios.get(`${api.adrese}/localitati/${judet}`);
        commit("SET_ROOT_FILTERS_OPTIONS", { key: "orase", value: data });
        commit("SET_ROOT_STATE", { key: "is_fetching_orase", value: false });
      } catch (err) {
        console.log(err);
        commit("SET_ROOT_STATE", { key: "is_fetching_orase", value: false });
      }
    },
  },

  plugins: [favoritesLocalStorage],
  modules: {
    auth,
    piese,
    profile,
    cereri,
  },
});
